@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800");
@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@300;400;500;600;700;800");


/* progress bar internal round right corners */
[role="progressbar"] {
  border-radius: 0 10px 10px 0;
}

.blink {
  animation: pulse_green 2s infinite;
}
.glow-primary{
  animation: glow_primary 2s infinite;
}
.glow-primary:hover{
  animation: none;
}

@keyframes glow_primary {
  0% {
    box-shadow: 0 0 0 0 rgba(237, 137, 54, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(237, 137, 54, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(237, 137, 54, 0);
  }
}

@keyframes pulse_green {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(16, 201, 121, 0.7);
  }
  70% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    box-shadow: 0 0 0 10px rgba(16, 201, 121, 0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(16, 201, 121, 0);
  }
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:enabled:hover{
  background-color: transparent;
}
.p-treetable .p-treetable-tbody > tr:focus{
  outline: none;
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:focus{
  box-shadow: 0 0 0 0.2rem #ED893687;
}

.chart-with-full-width{
  width: 100%;
  height: 100%;
}
.border-radius-5 .css-146proq{
  border-radius: 5px;
}
.css-1xnle77>*:not(style)~*:not(style){
  margin-top: 0!important;
}

.css-1ei4c22>*:not(style)~*:not(style) {
  margin-top: 0!important;
}